import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import styles from "./Dashboard.module.css";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
// import Spinner from '../../components/UI/Spinner/Spinner';

export const Dashboard = (props) => {
  const [changePwdMode, setChangePwdMode] = useState(false);
  const [resData, setResData] = useState({ status: null, msg: null });
  const [changePwd, setChangePwd] = useState({
    password_old: {
      value: null,
      valid: false,
      msg: "Шинэ нууц үг өөр байх ёстой!",
    },
    password_new: {
      value: null,
      valid: "init",
      msg: "Нууц үг нийт 8 том, жижиг үсэг, тоо, тэмдэгт агуулсан байна!",
    },
    password_new2: {
      value: null,
      valid: "init",
      msg: "Давтсан шинэ нууц үг ижил байна!",
    },
  });

  const changePwdHandler = async (event) => {
    event.preventDefault();
    const updateChangePwd = { ...changePwd };
    updateChangePwd.password_old.value = event.target.password_old.value;
    setChangePwd(updateChangePwd);
    const pwds = {
      password_old: changePwd.password_old.value,
      password_new: changePwd.password_new.value,
    };
    const url = process.env.REACT_APP_API_BASE_URL + "/user/password";
    const token = localStorage.getItem("token");
    try {
      const postRes = await axios.post(url, pwds, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setChangePwdMode(false);
      setResData({ status: "green", msg: postRes.data.message });
    } catch (err) {
      // console.log(err.response.data.data);
      if (err.response.data.message === "Validation failed.") {
        setResData({ status: "red", msg: err.response.data.data[0].msg });
      } else setResData({ status: "red", msg: err.response.data.message });
    }
  };

  const inputPwdChangeHandle = (event, name) => {
    const updateChangePwdValid = { ...changePwd };
    updateChangePwdValid[name].value = event.target.value;

    if (name === "password_new") {
      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (strongRegex.test(event.target.value)) {
        updateChangePwdValid[name].valid = true;
        setResData({ status: null, msg: null });
      } else {
        updateChangePwdValid[name].valid = false;
        setResData({ status: "red", msg: changePwd[name].msg });
      }
    } else if (name === "password_new2") {
      if (event.target.value === changePwd.password_new.value) {
        updateChangePwdValid[name].valid = true;
        setResData({ status: null, msg: null });
      } else {
        updateChangePwdValid[name].valid = false;
        setResData({ status: "red", msg: changePwd[name].msg });
      }
    }
    if (name !== "password_new2") {
      const notIdentical =
        name === "password_old" ? "password_new" : "password_old";
      if (updateChangePwdValid[name].value === changePwd[notIdentical].value) {
        updateChangePwdValid.password_new.valid = false;
        setResData({ status: "red", msg: changePwd.password_old.msg });
      }
    }
    setChangePwd(updateChangePwdValid);
  };

  const changePwdView = changePwdMode ? (
    <form onSubmit={changePwdHandler}>
      <Input
        elementType="input"
        label="Одоогийн: "
        elementConfig={{
          name: "password_old",
          type: "password",
          placeholder: "Одоогийн нууц үг",
        }}
        changed={(event) => inputPwdChangeHandle(event, "password_old")}
      />
      <Input
        elementType="input"
        label="Шинэ нууц үг: "
        elementConfig={{
          name: "password_new",
          type: "password",
          placeholder: "Шинэ нууц үг",
        }}
        changed={(event) => inputPwdChangeHandle(event, "password_new")}
        styles={!changePwd.password_new.valid ? "red" : null}
      />
      <Input
        elementType="input"
        label="Шинэ давтах: "
        elementConfig={{
          name: "password_new2",
          type: "password",
          placeholder: "Шинийг давтаж оруул",
        }}
        changed={(event) => inputPwdChangeHandle(event, "password_new2")}
        styles={!changePwd.password_new2.valid ? "red" : null}
      />
      <Button
        btnType="Send"
        disabled={
          // Check password_new's are not null and valid Enable disable button
          changePwd.password_new.value !== null &&
          changePwd.password_new.valid &&
          changePwd.password_new2.value !== null &&
          changePwd.password_new2.valid
            ? false
            : true
        }
      >
        Cолих
      </Button>
      <Button
        btnType="Danger"
        clicked={(event) => {
          event.preventDefault();
          setChangePwdMode(false);
          setResData({ status: null, msg: null });
        }}
      >
        Болих
      </Button>
      <div hidden={resData.status !== "red"} style={{ color: resData.status }}>
        {resData.msg}
      </div>
    </form>
  ) : (
    <div>
      <Button
        btnType="Send"
        clicked={() => {
          setChangePwdMode(true);
        }}
      >
        Нууц үг солих
      </Button>
      <div style={{ color: resData.status }}>{resData.msg}</div>
    </div>
  );

  return (
    <div className={styles.Dashboard}>
      <h2>Spark beauty</h2>
      <Link to="/revenue">
        <Button btnType="Send">Орлого бүртгэх</Button>
      </Link>
      <hr />
      <h3>ХААН БАНК: {props.bankaccounts[0].accountNo}</h3>
      <Link to="/bank">
        <Button btnType="Send">Данс шалгах</Button>
      </Link>
      <hr />
      <h2>Тохигоо</h2>
      {changePwdView}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.token !== "init" && state.token !== null,
    bankaccounts: state.bankaccounts,
  };
};

export default connect(mapStateToProps)(Dashboard);
